import React, { useState, useEffect, useRef } from "react";
import logo from "./logo.png";
import "./App.css";

function App() {
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [countdownComplete, setCountdownComplete] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);

  /*
    declarative setInterval rewritten for hooks as useInterval found here:

    https://overreacted.io/making-setinterval-declarative-with-react-hooks/

  */
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (timeRemaining < 1) {
      setTimeRemaining(-1);
      setCountdownComplete(true);
    } else {
      setTimeRemaining(timeRemaining - 1);
      setTryAgain(false);
    }
  }, 1000);

  const restartCountdown = () => {
    if (!countdownComplete) {
      setTimeRemaining(120);
      setTryAgain(true);
    }
  };

  return (
    <div onMouseOver={restartCountdown} 
      onMouseMove={restartCountdown} 
      className="App">
      <div className="body">
        {tryAgain && <div>Try Again!</div>}
        {timeRemaining > 0 && (
          <div>
            <div className="countdown">{timeRemaining}</div>
            <p>
              <code>See if you can take a moment. </code>
              <br></br>
              <code>
                <em>120 seconds</em> without touching your mouse or keyboard.
              </code>
            </p>
          </div>
        )}
        {timeRemaining <= 0 && (
          <div>
            <div className="header">
              <img src={logo} className="logo" />
            </div>
            <h1>
              <code>Congratulations!</code>
            </h1>
            <h1>
              <code>
                Check out <a href="https://getclearspace.app">clearspace</a> for
                more!
              </code>
            </h1>
          </div>
        )}

        <a
          className="App-link"
          href="https://donothingfor2minutes.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          👋 Idea from Calm
        </a>
      </div>
    </div>
  );
}

export default App;
